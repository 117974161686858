// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calc-js": () => import("./../../../src/pages/calc.js" /* webpackChunkName: "component---src-pages-calc-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pillars-js": () => import("./../../../src/pages/pillars.js" /* webpackChunkName: "component---src-pages-pillars-js" */),
  "component---src-pages-supersecretcalc-js": () => import("./../../../src/pages/supersecretcalc.js" /* webpackChunkName: "component---src-pages-supersecretcalc-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-audio-js": () => import("./../../../src/templates/audio.js" /* webpackChunkName: "component---src-templates-audio-js" */),
  "component---src-templates-pod-js": () => import("./../../../src/templates/pod.js" /* webpackChunkName: "component---src-templates-pod-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-vid-js": () => import("./../../../src/templates/vid.js" /* webpackChunkName: "component---src-templates-vid-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-written-js": () => import("./../../../src/templates/written.js" /* webpackChunkName: "component---src-templates-written-js" */)
}

